.bg-tesumas {
  background-image: url("assets/images/falabella/jovenes/Falabella-Jovenes-Talentos3.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 110vh;
}

.green-border-sumas {
  border: 4px solid #86C12E; /* Ajusta el grosor y color del borde rojo según tus necesidades */
  border-radius: 20px; /* Ajusta el radio de las esquinas según tus necesidades */
}

.lila-border-sumas {
  border: 4px solid #CD0B51; /* Ajusta el grosor y color del borde azul según tus necesidades */
  border-radius: 20px; /* Ajusta el radio de las esquinas según tus necesidades */
}

.azul-border-sumas {
  border: 4px solid #2E5EB7; /* Ajusta el grosor y color del borde azul según tus necesidades */
  border-radius: 20px; /* Ajusta el radio de las esquinas según tus necesidades */
  height: 46vw;
}

.img-lateral{
  max-width: 100%;
  height: auto;
}
.vertical-react-player-wrapper {
  position: relative;
  overflow: hidden;
}

.vertical-react-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* styles.css */
.custom-react-player-sumas {
width: 100% !important;
height: 100% !important;
position: relative;
}

.custom-react-player .react-player {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
object-fit: cover;
}


.azul-border-sumas-video {
  border: 4px solid #2E5EB7; /* Ajusta el grosor y color del borde azul según tus necesidades */
  border-radius: 20px; /* Ajusta el radio de las esquinas según tus necesidades */
}

.pointer-cursor {
  cursor: pointer;
}